import React, { Component } from 'react';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import Currency from '../../utils/currency';

import { Creators as CartCreator } from '../../store/ducks/cart';

import { FaArrowLeft, FaCheck } from 'react-icons/fa';

import Header from '../../components/Header';

import iconComerAqui from '../../assets/image/icon.png'

import './styles.css'

class ScreenPizzaBorder extends Component {

    constructor(props) {
        super(props);

        window.scrollTo(0, 0);
        console.clear();

        if (!props.product.product) {
            this.props.history.push({ pathname: '/' });
            return;
        }

        const filtraBorda = props.product.product.attrs.tmPizza

        // let bordas = props.product.products.filter(({ tpProduto }) => tpProduto == 'O');
        // props.product.product.attrs.tmPizza


        let { bordas } = props.location;

        bordas = bordas.filter((item) => item.tmPizza === filtraBorda)

        if(bordas.length === 0){
            bordas = props.location.bordas;

        }

        // console.log(bordas)
        
        let total = 0;
        let maiorValor = 0;
        let { items } = props.location.pizza

        for (let item of items) {
            total = parseFloat(total) + parseFloat(item.total);
            if (item.value > maiorValor) {
                maiorValor = item.value;
            }
        }

        let existeEmpresa = localStorage.getItem("@comer-aqui/empresa");

        if (!existeEmpresa) {
            return;
        }

        let empresa = JSON.parse(existeEmpresa);

        // tpValordivisaoitem
        // 0 = Soma todos os valores
        // 1 = Pega o maior valor

        let tpValor = empresa.tpValordivisaoitem;

        if (tpValor == 1) {
            total = 0;
            for (let item of items) {
                total = parseFloat(total) + parseFloat(maiorValor * item.quantity);
                item.value = maiorValor * item.quantity;
                  
            }
            
        }


        this.state = {
            items,
            quantity_flavors: props.location.quantity_flavors,
            total: total / props.location.quantity_flavors,
            bordas
        };   
    }

    onError() {
        if (!this.state.errored) {
            this.setState({ errored: true });
        }
    }

    handleInput = (e, item) => {
        const { bordas } = this.state;

        for (const borda of bordas) {
            if (borda.idProduto === item.idProduto) {
                borda.checked = !borda.checked
            }
            else {
                borda.checked = false;
            }
        }
        
        this.setState( bordas );
    }    

    
    handleSubmit = () => {
        let borda = this.state.bordas.filter(({ checked }) => checked);
        borda = borda[0];

        // console.log(this.state.bordas.filter(({ checked }) => checked))
        // console.log("tudo da pizza =", this.state)
        // console.log("tudo da pizza =",  this.props.location.pizza)



        this.props.history.push({
            pathname: '/pizza-quantity',
            pizza: this.props.location.pizza,
            quantity_flavors: this.props.location.quantity_flavors,
            product: this.props.location.product,
            borda,
        });
    }

    render() {
        const { product } = this.props;

        const { errored, bordas } = this.state;

        return (
            <>
                <Header />
                <div>
                    <div className="container p-0 mb-5">
                        <div className="col-12 d-flex align-items-center justify-content-center mt-2">
                            {errored || !product.product.url ?
                                <img src={iconComerAqui} alt='Comer Aqui' className='img-fluid mb-1' style={{ opacity: .35, height: 72, objectFit: 'contain' }} />
                                :
                                <img height={100} src={product.product.url} alt={product.product.alt} className="product-note-img" onError={this.onError.bind(this)} />
                            }
                        </div>
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <h6>{this.props.product.product.name} - {Currency.formatterValue.format(this.state.total)}</h6>
                        </div>
                        {product.product.description && (
                            <div className="product-description">
                                <h6 className='m-0'>{product.product.description}</h6>
                            </div>
                        )}
                        <div className='bg-dark text-white container py-2'>
                            <h5 className='m-0'>Bordas</h5>
                        </div>

                        {bordas.map((item) => (
                            <label htmlFor={item.idProduto} key={item.idProduto} className="w-100 bg-white m-0 py-2">
                                <div className="container d-flex align-items-center">
                                    <input
                                        type="checkbox"
                                        id={item.idProduto}
                                        onClick={(e) => this.handleInput(e, item)}
                                        checked={item.checked}
                                        style={{ width: 24, height: 24 }}
                                    />
                                    <span className='ml-1'><b>{item.dsProduto}</b></span>
                                    <span className="ml-auto text-secondary"><b>{Currency.formatterValue.format(item.vlPreco)}</b></span>
                                </div>
                            </label>
                        ))}

                    </div>

                    <div className="espacamento" />

                    <div className="fixed-bottom">
                        <div className="row">
                            <div className="col-md-12 col-xl-12 col-sm-12 col-12 p-0">
                                <button className="btn btn-success btn-block p-3" onClick={this.handleSubmit}>
                                    Continuar <FaCheck />
                                </button>
                            </div>
                            <div className="col-md-12 col-xl-12 col-sm-12 col-12 p-0">
                                <button className="btn btn-dark btn-block btn-go-back" onClick={() => { this.props.history.goBack() }}>
                                    <FaArrowLeft /> Voltar
                                </button> 
                            </div>
                        </div>
                    </div>

                </div>
                
            </>
        );
    }
}

const mapStateToProps = state => ({ product: state.product, cart: state.cart });

const mapDispatchToProps = dispatch => bindActionCreators(CartCreator, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScreenPizzaBorder);
