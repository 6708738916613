export const GerarToken = (tokenApi) => {
  function gerandoNumeros() {
    const min = 3;
    const max = 8;

    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function dataAtual() {
    let data = new Date().toLocaleString("pt-BR", {
      timeZone: "America/Sao_Paulo",
    });
    data = data.substr(0, 10).split("/");
    data = data[0] + data[1] + data[2];

    return data;
  }

  function horaAtual() {
    let hora = new Date().toLocaleString("pt-BR", {
      timeZone: "America/Sao_Paulo",
    });
    hora = hora.substr(11, 9).replace(":", "").replace(":", "");
    return hora;
  }

  const numeroGerado = gerandoNumeros();
  const dataGerada = dataAtual();

  const horaGerada = horaAtual();

  let formatandoToken =
    numeroGerado * (dataGerada + horaGerada).replace(" ", "");

  const completaFomateToken = "0".repeat(17 - `${formatandoToken}`.length);

  formatandoToken = `${numeroGerado}` + completaFomateToken + `${formatandoToken}`;

  const token = tokenApi + formatandoToken;

  localStorage.setItem("tokenValido", token);
};
