import { QRScaner, Camera } from "react-qrcode-scan";
import React, { useEffect } from "react";
import "./styles.css";

export default class QRReader extends React.Component {
  state = {
    results: [],
    camera: null,
    qrcode: null,
    selectedCameraIndex: 1, // Índice inicial selecionado
  };

  handleCameraChange = (event) => {
    const selectedIndex = event.target.value;
    this.setState({ selectedCameraIndex: selectedIndex });
  };

  onError = (error) => {
    console.error("Erro na câmera:", error);
    // Lógica de tratamento de erro, se necessário
  };

  onScan = (data) => {
    console.log("Código QR escaneado:", data);
    this.props.onQRScan(data);
  };

  onActive = () => {
    const elements = document.getElementsByClassName("cameraa");
    for (let element of elements) {
      element.removeAttribute("style");
      if (element) {
        element.setAttribute("playsinline", "");
        element.setAttribute("webkit-playsinline", "");
      }
    }
  };

  render() {
    const { selectedCameraIndex } = this.state;

    return (
      <Camera
        onError={this.onError}
        className="cameraa"
        style={{
          width: "100%",
          height: "90%",
          maxHeight: "400px",
          marginBottom: "20px",
        }}
      >
        {(cameras) =>
          cameras.length > 0 ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <QRScaner
                className="cameraa"
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "400px",
                  marginBottom: "20px",
                  display: "flex",
                }}
                onScan={this.onScan}
                camera={cameras[selectedCameraIndex]}
                onError={this.onError}
                onActive={this.onActive}
              />
              <select
                className="custom-select"d
                value={selectedCameraIndex}
                onChange={this.handleCameraChange} // Usando onChange em vez de onClick
              >
                {cameras.map((camera, index) => (
                  <option key={index} value={index}>
                    {camera.name}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div>No Camera</div>
          )
        }
      </Camera>
    );
  }
}
