const INITIAL_STATE = {}

const Types = {
    SET_PRODUCTS: 'product/SET_PRODUCTS',
    SET_PRODUCT: 'product/SET_PRODUCT',
    GET_PRODUCT: 'product/GET_PRODUCT',
    REMOVE_PRODUCT: 'product/REMOVE_PRODUCT',
    REMOVE_COMPLEMENT: 'product/REMOVE_COMPLEMENT',
    ADD_COMPLEMENT: 'product/ADD_COMPLEMENT',
}

export default function product(state = INITIAL_STATE, action) {
    switch (action.type) {
        case Types.SET_PRODUCTS:
            return { ...state, products: action.payload.products };
        case Types.SET_PRODUCT:
            return { ...state, product: action.payload.product };
        case Types.GET_PRODUCT:
            return { state };
        case Types.REMOVE_PRODUCT:
            return {};
        case Types.REMOVE_COMPLEMENT:
            return { ...state, remove: action.payload.remove }
        case Types.ADD_COMPLEMENT:
            return { ...state, add: action.payload.add }
        default:
            return state;
    }
}

export const Creators = {
    setProducts: (products) => ({
        type: Types.SET_PRODUCTS,
        payload: {
            products
        }
    }),
    setProduct: (product) => ({
        type: Types.SET_PRODUCT,
        payload: {
            product
        }
    }),
    getProduct: () => ({
        type: Types.GET_PRODUCT
    }),
    removeProduct: () => ({
        type: Types.REMOVE_PRODUCT
    }),
    setRemoveComplement: (remove) => ({
        type: Types.REMOVE_COMPLEMENT,
        payload: {
            remove
        }
    }),
    setAddComplement: (add) => ({
        type: Types.ADD_COMPLEMENT,
        payload: {
            add
        }
    })
}