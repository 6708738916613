import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import { BASE_URL } from '../constants/BASE_URL';

const cache = setupCache({
    maxAge: 15 * 60 * 1000,
    exclude: {
        query: false
    }
});

const api = axios.create({
    baseURL: BASE_URL,
    adapter: cache.adapter
});

export default api;
