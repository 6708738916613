/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import React, { Component, useEffect } from 'react';

import './styles.css';

import logo from '../../assets/image/logo.png'
import gerence from '../../assets/image/GerenceLogo.png'



class Home extends Component {

    constructor(props) {
        

        super(props);
        
        
        window.scrollTo(0, 0);

        // Alterar essa variavel para False para poder testar no celular colocando apenas o IP
        let producao = true;

        if (!producao) {
            this.props.history.push({ pathname: "/?nomeEmpresa=gerence" });
        }

    }

    
    render() {

        const pixel = window.localStorage.getItem("dsChavepixel")

        useEffect(() => {
           if(pixel !== null && pixel !== undefined){
            console.log('Esse site tem pixel')
            import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
              ReactPixel.init(pixel) // facebookPixelId
              ReactPixel.pageView()
      
              router.events.on('routeChangeComplete', () => {
                ReactPixel.pageView()
              })
            })
           }
          }, [router.events])
  
           
        return (
            <>
                <div className='d-flex align-items-center justify-content-center' style={{ width: '100%', height: '100vh' }}>

                    <div className='d-flex flex-column'>
                        <img src={logo} alt='Comer Aqui' className='m-auto' />
                        <div className='d-flex flex-column text-center mt-5'>
                            <small className='text-muted'>desenvolvido por</small>
                            <a href='http://www.gerencesistemas.com.br' target='_blank' noreferer=''>
                                <img src={gerence} alt='Gerence Sistemas' className='m-auto' width={128} height={'auto'} />
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Home;
