import React, { Component } from 'react';

import ReactDOM from 'react-dom';

import { FaTimes } from 'react-icons/fa';

import './styles.css';

class MaisInfoModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    let { empresa } = this.props;

    return ReactDOM.createPortal(
      <div className="ui-modal-overlay-mais-info">
        <div className="ui-modal-mais-info">

          <div className="row p-2">
            <div className="col-md-6 col-xl-6 col-sm-6 col-6"></div>
            <div className="col-md-6 col-xl-6 col-sm-6 col-6 text-right">
              <button className="btn btn-sm btn-dark" type="button" onClick={this.props.onClickClose}>
                <FaTimes /> FECHAR
              </button>
            </div>

            {/* Cesar 21/07/2022 */}
            {empresa.logo && (
              <div className='col-12 my-3'>
                <div className='d-flex justify-content-center'>
                  <img src={`data:image/jpeg;base64,${empresa.logo}`} alt="Logo da empresa" style={{ maxWidth: '100%', height: '128px' }} />
                </div>
              </div>
            )}

            <div className="col-md-12 col-xl-12 col-sm-12 col-12 pt-0 pb-0">
              Razão social: <strong>{empresa.razaoSocial}</strong>
            </div>
            <div className="col-md-12 col-xl-12 col-sm-12 col-12 pt-0 pb-0">
              Endereço: <strong>{empresa.endereco}</strong>
            </div>
            <div className="col-md-12 col-xl-12 col-sm-12 col-12 pt-0 pb-0">
              Bairro: <strong>{empresa.bairro}</strong>
            </div>
            <div className="col-md-12 col-xl-12 col-sm-12 col-12 pt-0 pb-0">
              Nº: <strong>{empresa.nrLogradouro}</strong>
            </div>
            <div className="col-md-12 col-xl-12 col-sm-12 col-12 pt-0 pb-0">
              CEP: <strong>{empresa.cep}</strong>
            </div>
            <div className="col-md-12 col-xl-12 col-sm-12 col-12 pt-0 pb-0">
              Telefone: <strong>{empresa.telefone}</strong>
            </div>
          </div>
        </div>
      </div>
      , document.getElementById('portal-root'))
  }
}

export default MaisInfoModal;