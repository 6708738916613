export const cepMask = value => {
    return value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')  
    .replace(/(-\d{3})\d+?$/, '$1') 
}

export const cellMask = value => {
    return value
    .replace(/\D/g, '')
    .replace(/(\d{1})(\d)(\d)/, '($1$2)$3')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1')
}