import React, { Component } from 'react';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import Currency from '../../utils/currency';

import './styles.css';

import { Creators as CartCreator } from '../../store/ducks/cart';

import { FaArrowLeft, FaCheck, FaPlus, FaMinus } from 'react-icons/fa';

import Header from '../../components/Header';

import { toast } from 'react-toastify';

import iconComerAqui from '../../assets/image/icon.png'

class ScreenProductNote extends Component {

    voltar = () => {

         if (localStorage.getItem('@comer-aqui/typeOrder') == "ENTREGA") {
            //   this.props.history.push({ pathname: '/' })
            this.props.history.push( '/' + localStorage.getItem('@comer-aqui/empresaUrl') )


        } else {
            // console.log("teste 56 = ", '/' + localStorage.getItem('@comer-aqui/empresaUrl') + '/?id=' + localStorage.getItem('@comer-aqui/authorization'))
            this.props.history.push( '/' + localStorage.getItem('@comer-aqui/empresaUrl') + '/?id=' + localStorage.getItem('@comer-aqui/authorization'));
        }

    }

    handleSubmit = () => {
        let { grupoObservacao, remove } = this.state;

        for (let observacao of grupoObservacao) {
            let radioChecked = false;
            let totalCheckeds = 0;
            for (let item of observacao.itens) {
                if (observacao.qt_maximo == 1) {
                    if (item.checked) {
                        radioChecked = true;
                    }
                }
                if (item.checked) {
                    totalCheckeds++;
                }
            }

            if (observacao.qt_minimo > 0) {
                if (observacao.qt_selecionada < observacao.qt_minimo) {
                    if (observacao.qt_maximo == 1) {
                        toast.error(`Selecione uma opção de "${observacao.ds_obs}"`);
                    }
                    else {
                        toast.error(`Selecione o mínimo de ${observacao.qt_minimo} item(ns) em "${observacao.ds_obs}"`);
                    }
                    return;
                }
            }

            if (totalCheckeds > 0) {
                observacao.mostrarCarrinho = true;
            } else {
                observacao.mostrarCarrinho = false;
            }
        }

        // if (ingredientes && ingredientes.length > 0) {
        //     this.props.history.push({
        //         pathname: '/remove',
        //         adicionais,
        //         note: grupoObservacao,
        //         telaAnterior: "product-note"
        //     });
        //     return;
        // } else if (adicionais && adicionais.length > 0) {
        //     this.props.history.push({
        //         pathname: '/add',
        //         adicionais,
        //         note: grupoObservacao,
        //         telaAnterior: "product-note"
        //     });
        //     return;
        // } else {
        this.props.history.push({
            pathname: '/product',
            note: grupoObservacao,
            telaAnterior: "product-note",
            remove,
        });
        return;
        // }
    }

    constructor(props) {
        super(props);

        window.scrollTo(0, 0);

        if (!props.product.product) {
            this.props.history.push({ pathname: '/' });
            return;
        }

        let { product: { product: { attrs } } } = props;

        let grupoObservacao = [];

        if (attrs.grupoObservacao) {
            grupoObservacao = attrs.grupoObservacao;
        }

        let i = 0;
        for (let observacao of grupoObservacao) {
            observacao.qt_selecionada = 0;

            for (let item of observacao.itens) {
                item.id = i++;
                item.checked = false;
                item.quantidade = 0;
                item.valor = 0;
            }

            if (!observacao.dsParentese) {
                let dsParentese = '';

                if (observacao.qt_maximo == 1) {
                    if (observacao.qt_minimo == 1) {
                        dsParentese = "obrigatório";
                    }
                    else {
                        dsParentese = "opcional";
                    }
                }
                else {
                    if (observacao.qt_gratis > 0) {
                        dsParentese = `${dsParentese}${observacao.qt_gratis.toString()} grátis`;
                    }

                    if (observacao.qt_minimo > 0) {
                        if (dsParentese != "") {
                            dsParentese = String.prototype.concat(dsParentese, ", ");
                        }

                        dsParentese = `${dsParentese}mínimo ${observacao.qt_minimo}`;
                    }

                    if (observacao.qt_maximo > 0) {
                        if (dsParentese != "") {
                            dsParentese = String.prototype.concat(dsParentese, ", ");
                        }

                        dsParentese = String.prototype.concat(dsParentese, "máximo ", observacao.qt_maximo.toString());
                    }

                    if (observacao.maxItem > 0) {
                        if (dsParentese != "") {
                            dsParentese = String.prototype.concat(dsParentese, ", ");
                        }

                        dsParentese = String.prototype.concat(dsParentese, "até ", observacao.maxItem.toString(), " de cada");
                    }
                }

                if (dsParentese != "") {
                    dsParentese = String.prototype.concat("(", dsParentese, ")");;
                }

                observacao.dsParentese = dsParentese;
            }
        }

        let { ingredientes } = attrs;

        this.state = {
            grupoObservacao,
            ingredientes,
            adicionais: props.location.adicionais,
            telaAnterior: props.location.telaAnterior,
            remove: props.location.remove,
            errored: false
        }
    }


    onError() {
        if (!this.state.errored) {
            this.setState({ errored: true });
        }
    }


    handleInput = (e, keyObservacao, keyItem) => {
        let { grupoObservacao } = this.state;

        let iObs = 0;
        let iObsItem = 0;
        for (let observacao of grupoObservacao) {
            if (iObs == keyObservacao) {
                for (let observacaoItem of observacao.itens) {
                    if ((observacao.qt_maximo == 1) && (observacao.qt_minimo == 1)) { //RadioButton
                        if (iObsItem == keyItem) { //Item clicado
                            observacaoItem.checked = true;
                            observacaoItem.valor = observacaoItem.vl_item;
                            observacao.qt_selecionada = 1;
                            observacaoItem.quantidade = 1;
                        }
                        else if (iObs == keyObservacao) { //Item diferente do clicado
                            observacaoItem.checked = false;
                            observacaoItem.quantidade = 0;
                            observacaoItem.valor = 0;
                        }
                    }
                    else {
                        if (iObsItem == keyItem) {
                            if (observacaoItem.checked) {
                                observacaoItem.checked = false;
                                observacao.qt_selecionada--;
                                observacaoItem.quantidade = 0;
                                observacaoItem.valor = 0;
                            }
                            else
                                if ((observacao.qt_maximo > observacao.qt_selecionada) || (observacao.qt_maximo == 0)) {
                                    observacaoItem.checked = true;
                                    observacao.qt_selecionada++;
                                    observacaoItem.quantidade = 1;
                                    if (observacao.qt_selecionada > observacao.qt_gratis) {
                                        observacaoItem.valor += observacaoItem.vl_item;
                                    }
                                }
                                else {
                                    toast.error("Ops... A quantidade máxima para estes complementos é de ".concat(observacao.qt_maximo).concat(" item(ns)"));
                                }
                        }
                    }

                    iObsItem++;
                }
            }
            iObs++;
        }

        for (let observacao of grupoObservacao) {
            if (observacao.qt_gratis >= observacao.qt_selecionada) {
                for (let observacaoItem of observacao.itens) {
                    observacaoItem.valor = 0;
                }
            }
        }

        this.setState({ grupoObservacao });
    }

    handleClickQuantity = (item, type) => {
        let { grupoObservacao } = this.state;

        for (let observacao of grupoObservacao) {
            for (let observacaoItem of observacao.itens) {
                if (observacaoItem == item) {
                    if (!observacao.qt_selecionada) {
                        observacao.qt_selecionada = 0;
                    }

                    if (type === "add") {
                        if ((observacaoItem.quantidade < observacao.maxItem) || (observacao.maxItem == 0)) {
                            if ((observacao.qt_selecionada < observacao.qt_maximo) || (observacao.qt_maximo == 0)) {
                                observacao.qt_selecionada++;
                                observacaoItem.quantidade++;

                                if (observacao.qt_selecionada > observacao.qt_gratis) {
                                    observacaoItem.valor += observacaoItem.vl_item;
                                }
                            }
                            else {
                                toast.error("A quantidade máxima para estes complementos é de ".concat(observacao.qt_maximo).concat(" item(ns)"));
                            }
                        }
                        else {
                            toast.error("A quantidade máxima por item é ".concat(observacao.maxItem));
                        }
                    }
                    else
                        if (observacaoItem.quantidade >= 1) {

                            observacao.qt_selecionada--;
                            observacaoItem.quantidade--;

                            if (observacaoItem.valor > 0) {
                                observacaoItem.valor -= observacaoItem.vl_item;
                            }
                        }

                    observacaoItem.checked = (observacaoItem.quantidade > 0);

                    // observacaoItem.valor = observacaoItem.quantidade * observacaoItem.vl_item;
                }
            }
        }

        for (let observacao of grupoObservacao) {
            if (observacao.qt_gratis >= observacao.qt_selecionada) {
                for (let observacaoItem of observacao.itens) {
                    observacaoItem.valor = 0;
                }
            }
        }

        this.setState({ grupoObservacao });
    }

    render() {
        const { product } = this.props;

        const { grupoObservacao, errored } = this.state;

        return (
            <>
                <Header />
                <div className="container p-0">
                    <div className="col-12 d-flex align-items-center justify-content-center mt-2">
                        {errored || !product.product.url ?
                            <img src={iconComerAqui} alt='Comer Aqui' className='img-fluid' style={{ opacity: .35, height: 72, objectFit: 'contain' }} />
                            :
                            <img height={100} src={product.product.url} alt={product.product.alt} className="product-note-img" onError={this.onError.bind(this)} />
                        }
                    </div>
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        {/* Cesar - 21/07/2022 */}
                        {localStorage.getItem('@comer-aqui/typeOrder') == 'MESA' ? (
                            <>
                                <h6>{this.props.product.product.name} - {Currency.formatterValue.format(this.props.product.product.vlPreco)}</h6>
                            </>
                        ) : (
                            <>

                                {this.props.product.product.promotion_price > 0 &&
                                    <h6>{this.props.product.product.name} - {Currency.formatterValue.format(this.props.product.product.promotion_price)}</h6>}
                                {(this.props.product.product.promotion_price == 0) || (this.props.product.product.promotion_price == null) &&
                                    <h6>{this.props.product.product.name} - {Currency.formatterValue.format(this.props.product.product.price)}</h6>}

                            </>
                        )}
                    </div>
                    <div className="product-description">
                        <h6>{product.product.description}</h6>
                    </div>

                    <div className="product-note-content" id="portal-root">
                        {grupoObservacao.length > 0 &&
                            grupoObservacao.map((observacao, keyObservacao) => (
                                <div className="product-note row" key={keyObservacao}>
                                    <div className="row" key={keyObservacao}>
                                        <div className="col-md-12 d-flex titulo">
                                            <h6 className="mr-2">{observacao.ds_obs}</h6> {observacao.ds_titulo} {observacao.dsParentese}
                                        </div>
                                        {observacao.itens.map((item, keyItem) => (
                                            <div className="col-md-12 input-item" key={keyItem}>
                                                {observacao.qt_maximo == 1 && observacao.qt_minimo == 1 &&
                                                    <div className="input-item-information">
                                                        <div className="form-check" style={{ marginBottom: "10px" }}>
                                                            <input
                                                                onClick={(e) => this.handleInput(e, keyObservacao, keyItem)}
                                                                checked={item.checked}
                                                                className="form-check-input"
                                                                onChange={() => this.handleInput()}
                                                                type="radio"
                                                                name={`${keyObservacao}-${keyItem}-${item.id_obs}-${item.id_obsitem}-${item.vl_item}-${item.ds_item}`}
                                                                id={`${keyObservacao}-${keyItem}-${item.id_obs}-${item.id_obsitem}-${item.vl_item}-${item.ds_item}`}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`${keyObservacao}-${keyItem}-${item.id_obs}-${item.id_obsitem}-${item.vl_item}-${item.ds_item}`}
                                                            >
                                                                <span className="ds_item">{item.ds_item}</span>
                                                                {
                                                                    // observacao.qt_maximo > 1 && observacao.qt_minimo > 1 &&
                                                                    item.vl_item > 0 &&
                                                                    <span className="vl_item">({Currency.formatterValue.format(item.vl_item)})</span>
                                                                }
                                                            </label>
                                                        </div>
                                                        <span className="valor">{Currency.formatterValue.format(item.valor)}</span>
                                                    </div>

                                                }
                                                {(observacao.maxItem == 1 || observacao.qt_maximo == 1) && (observacao.qt_minimo != 1) &&
                                                    <div className="input-item-information">
                                                        <div className="form-check" style={{ marginBottom: "10px" }}>
                                                            <input
                                                                checked={item.checked}
                                                                onChange={(e) => this.handleInput(e, keyObservacao, keyItem)}
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id={`${keyObservacao}-${keyItem}-${item.id_obs}-${item.id_obsitem}-${item.vl_item}-${item.ds_item}`}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`${keyObservacao}-${keyItem}-${item.id_obs}-${item.id_obsitem}-${item.vl_item}-${item.ds_item}`}
                                                            >
                                                                <span className="ds_item">{item.ds_item}</span>
                                                                {
                                                                    // observacao.qt_maximo > 1 && observacao.qt_minimo > 1 &&
                                                                    (((observacao.qt_selecionada > observacao.qt_gratis) || (observacao.qt_gratis == 0)) && (item.vl_item > 0)) &&
                                                                    <span className="vl_item">({Currency.formatterValue.format(item.vl_item)})</span>
                                                                }
                                                            </label>
                                                        </div>
                                                        <span className="valor">{Currency.formatterValue.format(item.valor)}</span>
                                                    </div>
                                                }
                                                {observacao.maxItem != 1 && observacao.qt_maximo != 1 &&
                                                    <div className="input-item-information">
                                                        <div className="input-quantidade">
                                                            <button className="del-quantity" onClick={() => this.handleClickQuantity(item, "del")}>
                                                                <FaMinus />
                                                            </button>
                                                            <h6 className="m-3">{item.quantidade}</h6>
                                                            <button className="add-quantity" onClick={() => this.handleClickQuantity(item, "add")}>
                                                                <FaPlus />
                                                            </button>
                                                        </div>
                                                        <div className="form-check">
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`${keyObservacao}-${keyItem}-${item.id_obs}-${item.id_obsitem}-${item.vl_item}-${item.ds_item}`}
                                                            >
                                                                <span className="ds_item">{item.ds_item}</span>
                                                                {
                                                                    // observacao.qt_maximo > 1 && observacao.qt_minimo > 1 &&
                                                                    (((observacao.qt_selecionada > observacao.qt_gratis) || (observacao.qt_gratis == 0)) && (item.vl_item > 0)) &&
                                                                    <span className="vl_item">({Currency.formatterValue.format(item.vl_item)})</span>
                                                                }
                                                            </label>
                                                        </div>
                                                        <span className="valor">{Currency.formatterValue.format(item.valor)}</span>
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                        <div className="col-md-12"><hr /></div>
                                    </div>
                                </div>
                            ))
                        }
                        <div className="espacamento" />
                    </div>
                    <div className="fixed-bottom">
                        <div className="row">
                            <div className="col-md-12 col-xl-12 col-sm-12 col-12 p-0">
                                <button className="btn btn-success btn-block p-3" onClick={this.handleSubmit}>
                                    Continuar <FaCheck />
                                </button>
                            </div>
                            <div className="col-md-12 col-xl-12 col-sm-12 col-12 p-0">
                                {/* <button className="btn btn-dark btn-block btn-go-back" onClick={() => { this.props.history.goBack() }}> */}                           
                                <button className="btn btn-dark btn-block btn-go-back" onClick={() => { this.voltar() }}>
                                    <FaArrowLeft /> Voltar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({ product: state.product, cart: state.cart });

const mapDispatchToProps = dispatch => bindActionCreators(CartCreator, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScreenProductNote);
