import React, { Component } from 'react';

import './styles.css';

import Currency from '../../utils/currency';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { Creators as ProductCreator } from '../../store/ducks/product';

import { toast } from 'react-toastify';

import iconComerAqui from '../../assets/image/icon.png'

class ItemProduct extends Component {

    constructor(props) {
        super(props);

        let empresa = localStorage.getItem("@comer-aqui/empresa");

        this.state = {
            grupo: props.grupo,
            errored: false,
            empresa: empresa ? JSON.parse(empresa) : false,
            dataParams: props.dataParams,
        };
    }

    onError() {
        if (!this.state.errored) {
            this.setState({ errored: true });
        }
    }

    render() {

        const { id, grupo, grupo_id, name, url, description, vlPreco, price, alt, index, type, promotion_price, dataParams } = this.props;

        //  console.log("certo",this.props)

        const { errored, empresa } = this.state;

        // console.log("loja = ", empresa)

        const handleSelectedItem = (item) => {
            this.props.setProduct(item);
            this.props.setProducts(this.props.all_products);

            let { empresa } = this.state;

            if (!empresa) {
                return;
            }

            // Cesar - 20/07/2022
            if(dataParams) {
                
                if(localStorage.getItem('@comer-aqui/typeOrder') === 'MESA'){
                    if(empresa.tpSituacaoqrcode  === 0) {
                        toast.error("A mesa esta fechada, não é possível realizar pedidos!");
                        return;
                } else {
                    if (empresa.situacao === 0) {
                        toast.error("Loja fechada, não é possível realizar pedidos!");
                        return;
                    }
                }
            }
            }

            let { grupo: { adicionais, grupoObservacao } } = this.state;

            if ((!item.attrs.grupoObservacao) && (item.grupo.idObs > 0)) {
                item.attrs.grupoObservacao = grupoObservacao;
            }

            // PRODUTOS QUE NÃO POSSUEM FILHOS
            if (String(item.type) === 'N') {
                if (item.attrs.ingredientes && item.attrs.ingredientes.length > 0) {
                    item.history.push({ pathname: '/remove', grupoObservacao, adicionais });
                    return;
                }
                else if ((item.attrs.grupoObservacao) && (item.attrs.grupoObservacao.length > 0)) {
                    item.history.push({ pathname: '/product-note', grupoObservacao });
                    return;
                }
                else if (adicionais && adicionais.length > 0 && item.attrs.inComplemento === 'S') {
                    item.history.push({ pathname: '/add', adicionais });
                    return;
                }
                else {
                    item.history.push({ pathname: '/product', adicionais });
                    return;
                }
            }

            // PIZZA
            if (String(item.type) === 'E') {
                item.history.push({ pathname: '/pizza' });
            }

            // PRODUTOS QUE POSSUI FILHOS
            if (String(item.type) === 'G') {
                // item.history.push({ pathname: '/remove' });
                item.history.push({ pathname: '/select-products' });
            }
        }

        return (
            <>
                <article className="row" key={`${index}-${id}-${grupo_id}`}>
                    <div className="product-image-item d-flex col-3" 
                        onClick={() => { this.props.showModal(url) }}>
                        {errored || !url ?
                            <img src={iconComerAqui} alt='Comer Aqui' className='img-fluid' style={{ opacity: .35, height: 72, objectFit: 'contain' }} />
                            :
                            <img src={url} alt={alt} onError={this.onError.bind(this)} className='img-fluid' />
                        }
                    </div>

                    <div className="product pl-0 col-9" 
                        onClick={
                            () => { 
                                if(localStorage.getItem('@comer-aqui/typeOrder') === 'MESA'){
                                     empresa.tpSituacaoqrcode === 0 ? toast.error(`${empresa.fantasia} está fechada no momento, volte mais tarde!`) :  handleSelectedItem(this.props) 

                                } else{

                                    empresa.situacao === 0 ? toast.error(`${empresa.fantasia} está fechada no momento, volte mais tarde!`) :  handleSelectedItem(this.props) 

                                }
                                

                            
                            } 
                        }
                    >
                        <div className="product-name">
                            <span>{String(name)}</span>
                        </div>
                        {description &&
                            <div className="product-info">
                                <span>{String(description)}</span>
                            </div>
                        }
                        <div className="product-value">
                            
                            {dataParams ? (
                                <>
                                    {
                                        type == "G" &&
                                        <span className='a-partir-de'>A partir de:</span>
                                    }
                                    {
                                        type == "E" &&
                                        <span className='a-partir-de'>A partir de:</span>
                                    }
                                    <span>{Currency.formatterValue.format(vlPreco)}</span>
                                </>
                            ) : (
                                <>
                                    {
                                        type == "G" &&
                                        <span className='a-partir-de'>A partir de:</span>
                                    }
                                    {
                                        type == "E" &&
                                        <span className='a-partir-de'>A partir de:</span>
                                    }
                                    {promotion_price > 0 &&
                                        <span><del className='valor-antigo'>{Currency.formatterValue.format(price)}</del> {Currency.formatterValue.format(promotion_price)}</span>}
                                    {promotion_price == 0 || promotion_price == null &&
                                        <span>{Currency.formatterValue.format(price)}</span>}
                                </>
                            )}

                        </div>
                    </div>
                </article>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(ProductCreator, dispatch);

export default connect(null, mapDispatchToProps)(ItemProduct);