const INITIAL_STATE = [];
// const INITIAL_STATE = [
//     {
//         id: 1,
//         name: 'Coca Cola 350 ml',
//         url : 'https://distribuidorahideal.com.br/527-large_default/refri-coca-cola-350ml-c-12.jpg',
//         alt : 'Coca Cola 350 ml',
//         description : 'Refrigerante de cola',
//         price: '4.50',
//         type: 1,
//         group: 2
//     },
//     {
//         id: 2,
//         name: 'Cheese Tasty',
//         url : 'https://i.pinimg.com/originals/8b/61/11/8b611136ead0d4c247f0fef92925f284.jpg',
//         alt : 'Cheese Tasty',
//         description : 'pão, carne , queijo, alface e tomate',
//         price: '12.50',
//         type: 2,
//         group: 1,
//         remove: [
//             {
//                 id: 1000,
//                 name: 'alface',
//                 checked: true
//             },
//             {
//                 id: 1001,
//                 name: 'tomate',
//                 checked: false
//             },
//             {
//                 id: 1002,
//                 name: 'carne',
//                 checked: false
//             },
//             {
//                 id: 1003,
//                 name: 'molho',
//                 checked: false
//             }
//         ],
//         add: [
//             {
//                 id: 1010,
//                 name: 'bacon',
//                 price: 2.50,
//                 checked: false
//             },
//             {
//                 id: 1011,
//                 name: 'calabresa',
//                 price: 2.00,
//                 checked: false
//             },
//             {
//                 id: 1012,
//                 name: 'frango',
//                 price: 2.00,
//                 checked: false
//             },
//             {
//                 id: 1013,
//                 name: 'molho',
//                 price: 1.00,
//                 checked: false
//             }
//         ]
//     },
//     {
//         id: 3,
//         name: 'Cheese Burger',
//         url : 'https://www.pngkit.com/png/full/90-909821_cheeseburger-mcdonalds-happy-meal-burger.png',
//         alt : 'Cheese Burger',
//         description : 'pão, carne , queijo',
//         price: '10.50',
//         type: 2,
//         group: 1
//     } 
// ];

export default function products(state = INITIAL_STATE, action) {
    return state;
}