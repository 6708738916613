import React, { Component } from 'react';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { Creators as CartCreator } from '../../store/ducks/cart';

import Currency from '../../utils/currency';

import './styles.css';

import { FaTrash } from 'react-icons/fa';

class ItemCart extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { item, removeItem } = this.props;
        return (
            <div className="border-bottom">
                <div className="row">
                    <div className="col-md-8 col-xl-8 col-sm-8 col-8 d-flex flex-column justify-content-center">
                        <div>
                            {item.product.quantity > 0 && <span className="item-quantity">{item.product.quantity}</span>}
                            {item.product.quantity > 0 && <span> x </span>}
                            <span className="item-description">{item.product.name}</span>
                        </div>
                        <div className="pizzas-sabores">
                            {item.product.sabores && item.product.sabores.length > 0 &&
                                item.product.sabores.map((sabor) => (
                                    <div key={`${sabor.id}-${sabor.name}`} className="row">
                                        <div className="col-md-12 col-xl-12 col-sm-12 col-12 pt-0 pb-0">
                                            <span className="item-quantity">({sabor.quantity}{item.product.quantity_flavors && <span>/{item.product.quantity_flavors}</span>})</span>
                                            <span className="item-description-pizza">{sabor.name}</span>
                                            {/* <span className="item-value">{Currency.formatterValue.format(item.total)}</span> */}
                                        </div>
                                    </div>
                                ))
                            }

                            {
                                item.borda &&
                                <div key={`${item.borda.idProduto}-${item.borda.dsProduto}`} className="row">
                                    <div className="col-md-12 col-xl-12 col-sm-12 col-12 pt-0 pb-0">
                                        <span className="item-description-pizza">{'C/ ' + item.borda.dsProduto}</span>
                                        {/* <span className="item-value">{Currency.formatterValue.format(item.total)}</span> */}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="observacoes">
                            {item.product.note && item.product.note.length > 0 &&
                                item.product.note.map((observacao, keyObservacao) => (
                                    (observacao.qt_selecionada > 0) && <div className="" key={keyObservacao}>
                                        <small key={keyObservacao} className="">{observacao.ds_obs}:&nbsp;
                                            {observacao.itens.map((item, keyItem) => (
                                                item.checked && <span className="" key={keyItem}>
                                                    {item.quantidade}x {item.ds_item}&nbsp;
                                                </span>
                                            ))}
                                        </small>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="col-md-2 col-xl-2 col-sm-2 col-2 d-flex align-items-center justify-content-center">
                        <span className="item-value">{Currency.formatterValue.format(item.product.total)}</span>
                    </div>
                    <div className="col-md-2 col-xl-2 col-sm-2 col-2 d-flex flex-column align-items-center justify-content-center">
                        <button className="item-remove" onClick={() => { removeItem(item.id) }}>
                            <FaTrash color="#F01" size="22" />
                        </button>
                    </div>
                </div>
                {item.observation != "" &&
                    <div className="item-observation">
                        <span>obs.: </span>
                        <div className="observation">
                            <span>{item.observation}</span>
                        </div>
                    </div>
                }
                {
                    item.add !== undefined &&
                    <div>
                        {
                            item.add.map((addItem, i) => (
                                <span key={i}> ( C/ {addItem.name} ) </span>
                            ))
                        }
                    </div>
                }
                {
                    item.remove !== undefined &&
                    <div>
                        {
                            item.remove.map((removeItem, i) => (
                                <span key={i}> ( S/ {removeItem.name} ) </span>
                            ))
                        }
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({ cart: state.cart });

const mapDispatchToProps = dispatch => bindActionCreators(CartCreator, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemCart)