import React, { Component } from "react";

import "./styles.css";

import Header from "../../components/Header";

import ItemComplement from "../../components/ItemComplement";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import { Creators as ProductCreator } from "../../store/ducks/product";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import { toast } from "react-toastify";

import Currency from "../../utils/currency";

import iconComerAqui from "../../assets/image/icon.png";

class ScreenAddComplement extends Component {
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    let note = [];

    if (props.location.note) {
      note = props.location.note;
    }

    let { qtMaximoadicional, qtMinimoadicional } =
      this.props.product.product.attrs;

    this.state = {
      checkedItems: [],
      add: props.location.adicionais,
      remove: props.location.remove,
      activeButton: false,
      telaAnterior: props.location.telaAnterior,
      note,
      qtMaximoadicional,
      qtMinimoadicional,
      qtAdicional: 0,
    };

    this.handleChecked = this.handleChecked.bind(this);
  }

  handleChecked(e, price = 0, vlPreco = 0, id) {
    const idItem = parseInt(e.target.id); //Adrian 18/04/24

    const item = e.target.name;

    const isChecked = e.target.checked;

    const checkedItems = [...this.state.checkedItems];

    let add = {
      id: idItem,
      name: item,
      value: price,
    };

    let { qtAdicional } = this.state;

    if (isChecked) {
      checkedItems.push(add);
      this.setState({ qtAdicional: qtAdicional + 1 }, () => {});
    } else {
      checkedItems.splice(checkedItems.indexOf(add), 1);
      this.setState({ qtAdicional: qtAdicional - 1 }, () => {});
    }

    this.setState({ checkedItems }, () => {});
  }

  async componentDidMount() {
    this.setState({ activeButton: true });

    this.setState(
      {
        innerHeight: window.innerHeight * 0.01,
      },
      () => {
        document.documentElement.style.setProperty(
          "--vh",
          `${this.state.innerHeight * 100}px`
        );
      }
    );

    window.addEventListener("resize", () => {
      this.setState(
        {
          innerHeight: window.innerHeight * 0.01,
        },
        () => {
          document.documentElement.style.setProperty(
            "--vh",
            `${this.state.innerHeight * 100}px`
          );
        }
      );
    });
  }

  handleBack = () => {
    let { note, adicionais, telaAnterior } = this.state;

    // this.props.history.push({
    //     pathname: `/${telaAnterior}`,
    //     note,
    //     adicionais,
    //     telaAnterior: "add"
    // });

    this.props.history.goBack();

    // if (localStorage.getItem('typeorder') == 4) {
    //     this.props.history.push({ pathname: '/' })
    // } else {
    //     this.props.history.push( '/' + localStorage.getItem('@comer-aqui/empresaUrl') + '/?id=' + localStorage.getItem('@comer-aqui/authorization'));

    // }
  };

  handleSubmit = () => {
    const {
      note,
      add,
      remove,
      qtAdicional,
      qtMaximoadicional,
      qtMinimoadicional,
    } = this.state;

    if (qtMaximoadicional) {
      if (qtAdicional > qtMaximoadicional) {
        toast.error(
          `Você só pode selecionar até ${qtMaximoadicional} ingrediente(s)`
        );
        return;
      }
    }

    if (qtMinimoadicional) {
      if (qtAdicional < qtMinimoadicional) {
        toast.error(
          `Você deve selecionar ao menos ${qtMinimoadicional} ingrediente(s)`
        );
        return;
      }
    }

    this.props.setAddComplement(this.state.checkedItems);

    this.props.history.push({
      pathname: "/product",
      note,
      adicionais: add,
      remove,
      telaAnterior: "add",
    });
  };

  render() {
    let { add, qtMaximoadicional, qtMinimoadicional } = this.state;

    // console.log(add)

    return (
      <>
        <Header />
        <div className="col-12 d-flex align-items-center justify-content-center mt-2">
          {!this.props.product.product.url ? (
            <img
              src={iconComerAqui}
              alt="Comer Aqui"
              className="img-fluid mb-1"
              style={{ opacity: 0.35, height: 72, objectFit: "contain" }}
            />
          ) : (
            <img
              height={100}
              src={this.props.product.product.url}
              alt={this.props.product.product.alt}
              className="product-note-img"
            />
          )}
        </div>
        <div className="col-md-12 col-xl-12 col-sm-12 col-12 d-flex align-items-center justify-content-center">
          {localStorage.getItem("@comer-aqui/typeOrder") == "MESA" ? (
            <h6>
              {this.props.product.product.name} -{" "}
              {Currency.formatterValue.format(
                this.props.product.product.vlPreco
              )}
            </h6>
          ) : (
            <>
              {this.props.product.product.promotion_price > 0 && (
                <h6>
                  {this.props.product.product.name} -{" "}
                  {Currency.formatterValue.format(
                    this.props.product.product.promotion_price
                  )}
                </h6>
              )}
              {this.props.product.product.promotion_price == 0 ||
                (this.props.product.product.promotion_price == null && (
                  <h6>
                    {this.props.product.product.name} -{" "}
                    {Currency.formatterValue.format(
                      this.props.product.product.price
                    )}
                  </h6>
                ))}
            </>
          )}
        </div>

        {/* Cesar - 05/08/2022 */}
        {this.props.product.product.description && (
          <div className="product-description">
            <h6>{this.props.product.product.description}</h6>
          </div>
        )}

        <div className="title-add-complement">
          <span>Adicionar ingrediente?</span>
          {parseInt(qtMinimoadicional) > 0 &&
          parseInt(qtMaximoadicional) > 0 ? (
            <p className="info-qtadicional">
              Escolha entre {qtMinimoadicional} e {qtMaximoadicional} opções
            </p>
          ) : (
            ""
          )}
          {parseInt(qtMinimoadicional) == 0 &&
          parseInt(qtMaximoadicional) > 0 ? (
            <p className="info-qtadicional">
              Escolha entre 0 e {qtMaximoadicional} opções
            </p>
          ) : (
            ""
          )}
          {parseInt(qtMinimoadicional) > 0 &&
          parseInt(qtMaximoadicional) == 0 ? (
            <p className="info-qtadicional">
              Escolha ao menos {qtMinimoadicional} opções
            </p>
          ) : (
            ""
          )}
        </div>
        <div>
          <ul className="list-group mb-5">
            {add !== undefined && add.length > 0 ? (
              add.map((item) => (
                <ItemComplement
                  key={item.codProduto}
                  id={item.codProduto}
                  name={item.descricaoProduto}
                  price={item.precoVenda}
                  onChecked={this.handleChecked}
                />
              ))
            ) : (
              <span className="title-complement-not-found">
                {" "}
                Nenhum ingrediente encontrado :({" "}
              </span>
            )}
          </ul>
          <div className="fixed-bottom">
            <div className="row">
              <div className="col-md-6 col-xl-6 col-sm-6 col-6 p-0">
                <button
                  className="btn btn-dark btn-block btn-go-back"
                  onClick={this.handleBack}
                >
                  {" "}
                  <FaArrowLeft /> Voltar{" "}
                </button>
              </div>
              <div className="col-md-6 col-xl-6 col-sm-6 col-6 p-0">
                <button
                  className="btn btn-primary btn-block"
                  disabled={!this.state.activeButton}
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  Próximo <FaArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapsStateToProps = (state) => ({ product: state.product });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductCreator, dispatch);

export default connect(
  mapsStateToProps,
  mapDispatchToProps
)(ScreenAddComplement);
