import React from "react";

import { BrowserRouter as Router } from "react-router-dom";

import { Provider } from "react-redux";

import "./App.css";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import store from "./store";
import history from "./services/history";
import Routes from "./routes";
import ReactPixel from "react-facebook-pixel";

// (function (global) {
//     if (typeof (global) === "undefined") {
//         throw new Error("window is undefined");
//     }

//     let _hash = "!";
//     let noBackPlease = function () {
//         global.location.href += "#";
//         global.setTimeout(function () {
//             global.location.href += "!";
//         }, 50);
//     };

//     global.onhashchange = function () {
//         if (global.location.hash !== _hash) {
//             global.location.hash = _hash;
//         }
//     };

//     global.onload = function () {
//         noBackPlease();

//         document.body.onkeydown = function (e) {
//             let elm = e.target.nodeName.toLowerCase();
//             if (e.which === 8 && (elm !== 'input' && elm !== 'textarea')) {
//                 e.preventDefault();
//             }
//             e.stopPropagation();
//         };
//     }
// })(window);

// function disableBack() { window.history.forward(); }
// setTimeout("disableBack()", 0);
// window.onunload = function () { null };

const options = {
  autoConfig: true, // Configura automaticamente o pixel com base nas tags do script que você forneceu
  debug: false, // Habilita o modo de depuração
};

const App = () => (
  <>
    {ReactPixel.init("1042678920181730", options)}
    <Provider store={store}>
      {/* Cesar - 22/07/2022 */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <Router history={history}>
        <Routes />
      </Router>
    </Provider>
  </>
);

export default App;
